




























































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import PolicyPreferenceModal from "@/components/policyPreferenceModal/PolicyPreferenceModal.vue";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import alert from "@/mixins/alert";
import { formatDate } from "@/utils/dateUtils";
import {
  resetPhysicianAlert,
  cancelMeetingAlert,
  publishMeetingAlertContent,
  resetSupportMaterialAlert,
  publishAndNotifylert,
} from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { getDateObject, isSameDate } from "@/utils/dateUtils";
import { isNotEmpty } from "@/utils/object";
// import momenttz from "moment-timezone";

@Component({
  components: {
    AppModal,
    PolicyPreferenceModal,
  },
})
export default class MeetingForm extends Vue {
  @Action public createMeeting: any;
  @Action public updateMeeting: any;
  @Action public getMeeting: any;
  @Action public getBrands: any;
  @Action public getTopics: any;
  @Action public getCategories: any;
  @Action public getLocationDropdown: any;
  @Action public getPhysicians: any;
  @Action public setMeeting: any;
  @Action public initMeeting: any;
  @Action public getUserListForDropdown: any;
  @Action public getPoliciesAndPreferencesByLocationId: any;
  @Action public getRestaurants: any;
  @Getter public policyPreferenceList: any;
  @Getter public users: any;
  @Getter public meeting: any;
  @Getter public meetingInitialData: any;
  // @Getter public brands: any;
  // @Getter public topics: any;
  // @Getter public categories: any;
  @Getter public locations: any;
  @Getter public physicians: any;
  @Getter private role!: any;
  @Getter public restaurants: any;
  @Getter public currentUser: any;
  @Action private getLoggedInUser: any;
  // @Getter private recommendedPhysicians: any;

  isSubmitted = false;
  isValidForm = false;
  isDraftMode = false;
  isDontShowChecked = false;
  isDontShowCheckedToSupportMaterial = false;
  isDontShowCheckedToZoomNonzoom = false;
  isPhysicianLoaded = false;
  isViewModeActive = false;
  $refs!: {
    meetingForm: InstanceType<typeof ValidationObserver>;
    appModalRef: HTMLFormElement;
    policyPreferenceModalRef: HTMLFormElement;
  };
  refreshmentOptions = [
    { key: "LUNCH", value: "LUNCH" },
    { key: "SNACK", value: "SNACK" },
  ];
  showTextBox = false;
  currentDate = moment();
  remainder =
    15 -
    (this.currentDate.minute() % 15 === 0
      ? 15
      : this.currentDate.minute() % 15);
  roundedCurrentDate = moment(this.currentDate)
    .add(this.remainder, "minutes")
    .toISOString();

  dateObject = {
    startDate: this.roundedCurrentDate,
    endDate: this.roundedCurrentDate,
    startTime: this.roundedCurrentDate,
    endTime: moment(this.roundedCurrentDate).add(30, "minutes").toISOString(),
  };

  meetingTypes = [{ text: "Broadcast Mode", value: "broadcast" }];
  // meetingTypes = [
  //   { text: "Broadcast Mode", value: "broadcast" },
  //   { text: "Interactive Mode", value: "interactive" },
  //   { text: "Collaborative Mode", value: "collabrative" }
  // ];

  action = "add";
  physicianFilter = {
    fields: {
      id: true,
      first_name: true,
      last_name: true,
      department_name: true,
      location: true,
      prefix: true,
    },
    where: {},
    include: [
      {
        relation: "physiciansAvailabilities",
        scope: {
          fields: {
            id: true,
            physicianId: true,
            weekday: true,
            locationId: true,
            available: true,
          },
          where: {
            and: [{ weekday: 1 }, { locationId: 1 },  { available: {inq: this.AMPMvalue }}],
          },
          include: [
            {
              relation: "location",
              scope: {
                fields: { name: true, id: true },
              },
            },
          ],
        },
      },
    ],
  };
  isLoaded = false;
  field: any = {
    oldValue: "",
    name: "",
  };
  timezone = "";
  uploadTypes = [
    { text: "Link", value: "link" },
    { text: "File", value: "file" },
  ];
  zoomOrNonzoomOptions = [
    { text: "Zoom", value: "zoom" },
    { text: "Non Zoom", value: "nonzoom" },
  ];

  fileAcceptCriteria =
    "image/*,application/pdf,doc,docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain";
  fileRules: any = `mimes:${this.fileAcceptCriteria}|size:10240`;
  materialType = "link";
  meetLinkType = "nonzoom";
  fileAccept =
    ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,image/*";
  beforeMount() {
    // this.getBrands({ fields: { id: true, brand_name: true } });
    // this.getTopics({ fields: { id: true, topic_name: true } });
    // this.getCategories({ fields: { id: true, category_name: true } });
      this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    if (this.$route.params.id) {
      this.action = "edit";
      this.isPhysicianLoaded = true;
      this.getMeeting(this.$route.params.id);
    }
    if (this.$route.name == "meeting.view") {
      this.action == "view";
      this.isViewModeActive = true;
    }
    if(this.currentUser.tenant) {
      this.onCurrentUserChanged();
    }    
  }

  @Watch('currentUser')
  onCurrentUserChanged() {
    if(this.role === "SUPER_ADMIN") {
      this.getUserListForDropdown( { 
        where: { and: [{userTypesId: { inq: [5,2,3] }}, {status: {eq: 1}}], is_blocked:false },
        fields: ['id', 'first_name', 'last_name', 'email', 'tenantId', 'status', 'userTypesId']
      });
    } else if(this.role === "ADMIN") {
      this.getUserListForDropdown( { 
        where: { and: [{userTypesId: { inq: [5,3] }}, {status: {eq: 1}}], is_blocked:false }, 
        fields: ['id', 'first_name', 'last_name', 'email', 'tenantId', 'status', 'userTypesId']
      });
    }
    if(this.currentUser.tenant && this.currentUser.tenant.other_restaurant) {
      this.getRestaurants(
         {
          fields: ['id', 'name', 'status', 'display_order','city','state','country','zipcode'],
        order: ["display_order DESC", "name ASC"],
        where: { status: true },
      });
    } else {
      this.getRestaurants({
        fields: ['id', 'name', 'status', 'display_order','city','state','country','zipcode'],
      order: ["display_order DESC", "name ASC"],
      where: { status: true, and: [
        {
          id: { nin: [9999] },
        }
      ] },
    }
    );
    }
  }

  mounted() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 2000);
  }

  destroyed() {
    this.initMeeting();
  }

  get AMPMvalue(): string {
    const starttime = moment(this.dateObject.startTime, 'HH:mm:ss');

    const isStartTimeInAM = starttime.format('a') === 'am';
    const isStartTimeInPM = starttime.format('a') === 'pm';

    const endTime = moment(this.dateObject.endTime, 'HH:mm:ss');

    const isEndTimeInAM = endTime.format('a') === 'am';
    const isEndTimeInPM = endTime.format('a') === 'pm';

    if (isStartTimeInAM && isEndTimeInAM) {
      return `'AM/PM', 'AM'`;
    } else if (isStartTimeInPM && isEndTimeInPM) {
      return `'AM/PM', 'PM'`;
    } else {
      return 'AM/PM';
    }


  }
  get title(): string {
    if (this.meeting && this.meeting.id) {
      this.dateObject = {
        startDate: this.meeting.start_time,
        endDate: this.meeting.end_time,
        startTime: this.meeting.start_time,
        endTime: this.meeting.end_time,
      };
      if (this.isViewModeActive){
        return "View Meeting";
      }
      if (this.meeting.status) {
        return "Edit Meeting";
      }
      this.action = "editDraft";
      let startDateTime = '';
      let endDateTime = ''
      if(!moment(this.meeting.start_time).isDST()) {
        startDateTime = moment(this.meeting.start_time).add(1, 'hour').format("hh:mm A");
        endDateTime = moment(this.meeting.end_time).add(1, 'hour').format("hh:mm A");
      } else {
        startDateTime = formatDate(this.meeting.start_time, "hh:mm A");
        endDateTime = moment(this.meeting.end_time).format("hh:mm A");
      }
      return `${formatDate(this.dateObject.startDate, "DD MMM YYYY")} |  
      ${this.meeting.refreshment.toUpperCase()} | ${startDateTime} - ${endDateTime} @ ${
        this.meeting.location
      }`;
    }
    return "Create Meeting";
  }

  getPhysiciansList() {
    let whereConditionObj: any = {};
    whereConditionObj = {
      // optout_topic_ids: { regexp: `^[^${this.meeting.topicsId}]+$` },
      // optout_brand_ids: { regexp: `^[^${this.meeting.brandsId}]+$` },
      // optout_category_ids: { regexp: `^[^${this.meeting.categoriesId}]+$` },
    };
    if (this.meeting.location && this.meeting.location !== "All") {
      whereConditionObj["location"] = this.meeting.location;
    }
    // const weekday = moment(this.meeting.start_time).day();
    // whereConditionObj.and = [{ weekday: weekday }, { locationId: 1 }]
    this.physicianFilter.where = whereConditionObj;
    this.getPhysicians(this.physicianFilter);
    this.field = {};
  }

  setOrganizerId(value: any): void {
    this.meeting.organizerId = value;
  }

  restaurantSelected(value: any): void {
    this.meeting["restaurantsId"] = value;
    const selectedRestaurant = this.restaurants.find((restaurant: any) => {
      return restaurant.id === value;
    });
    if (selectedRestaurant.name.toLowerCase() === "other") {
      this.showTextBox = true;
    } else {
      this.showTextBox = false;
      this.meeting.other_restaurant = "";
    }
    this.setMeeting({ ...this.meeting });
  }

  updateFormToStore(field: string, value: any): void {
    if (this.meeting[field]) {
      this.field.name = field;
      this.field.oldValue = this.meeting[field];
    }
    this.meeting[field] = value;
    this.setMeeting(this.meeting);
    if (
      this.meeting.brandsId &&
      this.meeting.topicsId &&
      this.meeting.categoriesId &&
      field !== "participants"
    ) {
      this.resetPhysicianAlert();
    }
  }

  setMaterialType(value: any): any {
    this.field.name = "materialType";
    this.field.oldValue = this.materialType;
    this.materialType = value;
    if (
      (value === "file" && this.meeting.supporting_material.trim() !== "") ||
      (value === "link" && this.meeting.file)
    ) {
      if (this.isDontShowCheckedToSupportMaterial) {
        this.meeting.supporting_material = "";
        this.meeting.file = null;
        this.materialType = value;
      } else {
        alert.methods.conFirmAlert(
          resetSupportMaterialAlert,
          (isChecked: any) => {
            this.isDontShowCheckedToSupportMaterial = isChecked;
            this.meeting.supporting_material = "";
            this.meeting.file = null;
            this.materialType = value;
          },
          () => {
            this.materialType = this.field.oldValue;
            this.field = {};
          }
        );
      }
    }
  }

  setMeetLinkType(value: any): any {
    this.field.name = "zoom_nonzoom_meeting";
    this.field.oldValue = this.meeting.zoom_nonzoom_meeting;
    this.meeting.zoom_nonzoom_meeting = value;
    if (value === "zoom" && this.meeting.nonzoom_meeting_link.trim() !== "") {
      this.meeting.nonzoom_meeting_link = "";
      this.meeting.zoom_nonzoom_meeting = value;
    } else {
      this.meeting.nonzoom_meeting_link = "";
    }
  }

  setFileObj(value: any) {
    this.meeting.file = value;
  }

  /**
   * Notify with Publish and Publish & notify while creating metting
   */
  createMeetingAlert(): void {
    alert.methods.conFirmAlert(
      publishMeetingAlertContent,
      () => {
        this.meeting.status = 1;
        this.meeting.is_notify = true;
        this.createMeeting(this.meeting);
      },
      () => {}
    );
  }

  /**
   * Notify with Publish and Publish & notify while updating meeting with update invite
   */
  editMeetingAlert(): void {
    alert.methods.conFirmAlert(
      publishAndNotifylert,
      () => {
        this.meeting.is_notify = true;
        this.updateMeeting({
          data: { ...this.meeting, isEdit: true },
          callback: this.backToMeetings,
        });
      },
      () => {
        this.meeting.is_notify = false;
        this.updateMeeting({
          data: { ...this.meeting, isEdit: true },
          callback: this.backToMeetings,
        });
      }
    );
  }

  /**
   * Notify with Publish and Publish & notify while edit draft metting
   */
  draftMeetingAlert(): void {
    alert.methods.conFirmAlert(
      publishMeetingAlertContent,
      () => {
        this.meeting.is_notify = true;
        this.updateMeeting({
          data: this.meeting,
          callback: this.backToMeetings,
        });
      },
      () => {}
    );
  }

  resetPhysicianAlert(): any {
    if (this.isDontShowChecked || !this.isPhysicianLoaded) {
      this.getPhysiciansList();
      this.isPhysicianLoaded = true;
      this.meeting.participants = [];
      this.updateFormToStore("participants", []);
    } else {
      alert.methods.conFirmAlert(
        resetPhysicianAlert,
        (isChecked: any) => {
          this.isDontShowChecked = isChecked;
          this.meeting.participants = [];
          this.updateFormToStore("participants", []);
          this.getPhysiciansList();
        },
        () => {
          if (this.field && this.field.oldValue && this.field.name) {
            this.meeting[this.field.name] = this.field.oldValue;
            this.setMeeting(this.meeting);
            this.field = {};
          }
        }
      );
    }
  }

  dateUtils(): void {
    const dateObject = getDateObject(this.dateObject);
    this.meeting.start_time = dateObject.startDate;
    this.meeting.end_time = dateObject.endDate;
  }

  dateValidation(): void {
    const dateObject = getDateObject(this.dateObject);
    this.dateObject.startTime = dateObject.startDate;
    if (moment(this.dateObject.startTime).isBefore(this.roundedCurrentDate)) {
      this.dateObject.startTime = this.roundedCurrentDate;
      this.dateObject.endTime = moment(this.roundedCurrentDate)
        .add(30, "minute")
        .toISOString();
    } else {
      this.dateObject.endTime = dateObject.endDate;
    }
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.meeting.meeting_type = "broadcast";
    this.$refs.meetingForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (isNotEmpty(firstErrorFocused)) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.meetingForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.meetingForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.meeting.meeting_name = this.meeting.meeting_name.trim();
        this.meeting.nonzoom_meeting_link =
          this.meeting.nonzoom_meeting_link.trim();
        this.dateUtils();
        if (
          this.meeting.status &&
          moment(this.meeting.start_time).isBefore(moment())
        ) {
          alert.methods.appToaster({
            message: constants.START_TIME_ERROR_AT_SAVE,
            type: "ERROR",
          });
        // } else if (
        //   this.meeting.status &&
        //   moment(this.meeting.end_time).isSameOrBefore(
        //     moment(this.meeting.start_time)
        //   )
        // ) {
        //   alert.methods.appToaster({
        //     message: constants.END_TIME_ERROR_AT_SAVE,
        //     type: "ERROR",
        //   });
        } else {
          if (this.action === "add") {
            this.meeting.supporting_material =
              this.meeting.supporting_material || "";
            if (this.currentUser.tenant.other_restaurant &&
              this.meeting.restaurantsId === 9999 &&
              this.meeting.other_restaurant === null
            ) {
              this.meeting.other_restaurant = "Unknown restaurant";
            }
            this.meeting.status
              ? this.createMeetingAlert()
              : this.createMeeting(this.meeting);
          } else {
            delete this.meeting.created_at;
            delete this.meeting.updated_at;
            delete this.meeting.created_by;
            delete this.meeting.updated_by;
            delete this.meeting.zoom_details;
            delete this.meeting.appointment_label;
            delete this.meeting.appointmentTypeId;
            delete this.meeting.scheduled_open_at;
            if (
              this.meeting.restaurantsId === 9999 &&
              this.meeting.other_restaurant === null
            ) {
              this.meeting.other_restaurant = "Unknown restaurant";
            }
            if (
              this.meeting.participants &&
              typeof this.meeting.participants[0] === "object"
            ) {
              this.meeting.participants = this.meeting.participants.map(
                (participant: any) => {
                  return participant.id;
                }
              );
            }
            if (this.action === "edit") {
              this.meeting.supporting_material =
                this.meeting.supporting_material || "";
              this.editMeetingAlert();
            } else {
              this.meeting.supporting_material =
                this.meeting.supporting_material || "";
              this.meeting.status
                ? this.draftMeetingAlert()
                : this.updateMeeting({
                    data: this.meeting,
                    callback: this.backToMeetings,
                  });
            }
          }
        }
      }
    });
  }

  /*  This method needs to called when status is set as true
   *  We have using this to check whether a meeting has no participants.
   */
  CheckNoParticipants(): boolean {
    return (
      this.meeting.status &&
      this.isSubmitted &&
      this.meeting.participants.length === 0 &&
      this.meeting.topicsId &&
      this.meeting.brandsId &&
      this.meeting.categoriesId
    );
  }

  openAttendeeListModal(): void {
    const appModal = this.$refs.appModalRef;
    appModal.show();
  }

  showPolicyAndPreference(): void {
    const appModal = this.$refs.policyPreferenceModalRef;
    this.getPoliciesAndPreferencesByLocationId({
      data: this.meeting.locationId,
      callBack: () => appModal.show(),
    });
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        if(this.currentUser.is_medical_rep){
        this.getLoggedInUser();
        }
        this.$router
          .push(
            this.$route.name === "meeting.update"
              ? { name: "meeting.list" }
              : { name: "book-meeting.list" }
          )
          .catch();
      },
      () => {}
    );
  }

  backToBookedMeetings() {
    this.$router.push({ name: "meeting.list" }).catch();
  }

  backToMeetings() {
    this.$router
      .push(
        this.$route.name === "meeting.update"
          ? { name: "meeting.list" }
          : { name: "book-meeting.list" }
      )
      .catch();
  }

  setStartDate(date: any): void {
    if (this.isLoaded) {
      this.dateObject.startDate = date;
      this.dateObject.endDate = date;
      // This method needs to called for joining date and time
      // We have using date and time at seperate fields
      this.dateValidation();
    }
  }

  setStartTime(time: any): void {
    if (this.isLoaded) {
      this.dateObject.startTime = time;
      this.dateObject.endTime = moment(time).add(30, "minutes").toISOString();
      if (
        !isSameDate(this.dateObject.startTime, this.dateObject.endTime) &&
        isSameDate(this.dateObject.startDate, this.dateObject.endDate)
      ) {
        this.dateObject.endDate = this.dateObject.endTime;
      }
    }
  }

  setEndTime(time: any): void {
    if (this.isLoaded) {
      this.dateObject.endTime = time;
    }
  }

  setEndDate(time: any): void {
    if (this.isLoaded) {
      this.dateObject.endDate = time;
    }
  }

  setMeetingStatus(status: number) {
    this.meeting.status = status;
  }

  get endTimeObj() {
    return this.dateObject.endTime;
  }

  get getParticipants() {
    return  this.meeting.participants;
  }
}
